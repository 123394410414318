exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-author-profile-layout-js": () => import("./../../../src/templates/AuthorProfileLayout.js" /* webpackChunkName: "component---src-templates-author-profile-layout-js" */),
  "component---src-templates-contentful-asset-layout-js": () => import("./../../../src/templates/ContentfulAssetLayout.js" /* webpackChunkName: "component---src-templates-contentful-asset-layout-js" */),
  "component---src-templates-contentful-download-layout-js": () => import("./../../../src/templates/ContentfulDownloadLayout.js" /* webpackChunkName: "component---src-templates-contentful-download-layout-js" */),
  "component---src-templates-contentful-layout-js": () => import("./../../../src/templates/ContentfulLayout.js" /* webpackChunkName: "component---src-templates-contentful-layout-js" */),
  "component---src-templates-contentful-news-category-layout-js": () => import("./../../../src/templates/ContentfulNewsCategoryLayout.js" /* webpackChunkName: "component---src-templates-contentful-news-category-layout-js" */),
  "component---src-templates-markdown-page-layout-js": () => import("./../../../src/templates/MarkdownPageLayout.js" /* webpackChunkName: "component---src-templates-markdown-page-layout-js" */),
  "component---src-templates-news-layout-js": () => import("./../../../src/templates/NewsLayout.js" /* webpackChunkName: "component---src-templates-news-layout-js" */)
}

